<template>
  <div class="flex w-full flex-1 h-full relative z-[1]">
    <div
      id="map_canvas"
      class="
        absolute
        bottom-16
        right-12
        sm:w-48 sm:h-32
        w-60
        h-44
        rounded-lg
        lg:bottom-28
        z-[2]
      "
    ></div>

    <div id="map-street" class="w-full h-full"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      own_parcelles: [],
    }
  },
  computed: {
    ...mapGetters({
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
    }),
  },

  created() {
    this.own_parcelles = [this.current_parcelle, ...this.parcelles_voisines]
    if (this.map) {
      this.map.on('webglcontextlost', () => {
        this.removeMapbox()
      })
    }
  },
  mounted() {
    this.$loader.load().then((google) => {
      this.init(google)
    })
  },

  methods: {
    removeMapbox() {
      if (this.map) {
        try {
          this.map.remove()
          this.$store.commit('map/MAP', null)
        } catch (e) {
          console.log(e)
        }
      }
    },
    init(google) {
      let coordinates = this.address.information.geometry.coordinates
      var current_position = {
        lat: parseFloat(coordinates[1]),
        lng: parseFloat(coordinates[0]),
      }
      let map_canvas = new google.maps.Map(
        document.getElementById('map_canvas'),
        {
          center: current_position,
          zoom: 18,
          disableDefaultUI: false,
          fullscreenControl: false,
        },
      )

      this.$emit('data_google_map', {
        google_map: map_canvas,
        google: google,
      })

      new google.maps.Marker({
        position: current_position,
        map: map_canvas,
        icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|FFFF00',
        title: 'Terrain',
      })
      this.addGeoJsonToGoogleMap(map_canvas)

      var svService = new google.maps.StreetViewService()
      var panoRequest = {
        location: current_position,
        radius: 1000,
      }

      var findPanorama = function (radius) {
        panoRequest.radius = radius
        svService.getPanorama(panoRequest, function (panoData, status) {
          if (status === google.maps.StreetViewStatus.OK) {
            var whereToLookLatLng = new google.maps.LatLng(
              parseFloat(current_position.lat),
              parseFloat(current_position.lng),
            )

            var panorama = new google.maps.StreetViewPanorama(
              document.getElementById('map-street'),
              {
                pano: panoData.location.pano,
                disableDefaultUI: true,
              },
            )
            // MamLatLng is the position of the point of view
            var ManLatLng = panoData.location.latLng
            // Calculate the heading to point the view in the direction of whereToLookLatLng
            var heading = google.maps.geometry.spherical.computeHeading(
              ManLatLng,
              whereToLookLatLng,
            )
            // get the old Pov values
            var pov = panorama.getPov()
            // set New Pov.heading
            pov.heading = heading
            panorama.setPov(pov)
            new google.maps.Marker({
              position: current_position,
              map_canvas: panorama,
              icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|FFFF00',
              title: 'Terrain',
            })
            map_canvas.setStreetView(panorama)
          } else {
            //Handle other statuses here
            if (radius > 100000) {
              alert("Street View n'est pas disponible")
            } else {
              findPanorama(radius + 500)
            }
          }
        })
      }

      findPanorama(50)
    },
    addGeoJsonToGoogleMap(map_canvas) {
      var stateLayer = new google.maps.Data()
      this.own_parcelles.forEach((parcelle) => {
        var temp_geojson = JSON.parse(JSON.stringify(parcelle))
        map_canvas.data.addGeoJson(temp_geojson)
        stateLayer.addGeoJson(temp_geojson)
      })
      map_canvas.data.setStyle(() => {
        return {
          fillColor: '#321E6A',
          strokeColor: '#321E6A',
          strokeWeight: 2,
        }
      })
      this.zoom(map_canvas)
    },
    zoom(map_canvas) {
      var bounds = new google.maps.LatLngBounds()
      map_canvas.data.forEach((feature) => {
        this.processPoints(feature.getGeometry(), bounds.extend, bounds)
      })
      map_canvas.fitBounds(bounds)
    },

    processPoints(geometry, callback, thisArg) {
      if (geometry instanceof google.maps.LatLng) {
        callback.call(thisArg, geometry)
      } else if (geometry instanceof google.maps.Data.Point) {
        callback.call(thisArg, geometry.get())
      } else {
        geometry.getArray().forEach((g) => {
          this.processPoints(g, callback, thisArg)
        })
      }
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off('webglcontextlost', this.removeMapbox)
    }
  },
}
</script>
