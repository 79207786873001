<template>
  <div class="flex flex-1">
    <LayoutMapAndTools>
      <template #layers>
        <MapType :data_google_map="data_google_map" class="z-40" />
      </template>
      <template #map>
        <Map
          @data_google_map="
            (value) => {
              data_google_map = value
            }
          "
        ></Map>
      </template>
      <template #footer-details-infos>
        <div
          class="desktop:hidden w-full bg-white flex items-center justify-start px-4 py-5 text-promy-primary font-bold font-main text-sm"
        >
          <div class="w-10 h-10 rounded-full flex justify-center items-center">
            <MarkerIcon name="marker" class="w-7 h-7 mr-4" color="#10AAAF" />
          </div>
          <div class="flex flex-col ml-3 sm:text-lg text-xl">
            <span class="text-promy-green-350 lg:flex">
              {{ address.information.properties.label | truncate(24, '...') }}
            </span>
            <span> Street view </span>
          </div>
        </div>
      </template>
    </LayoutMapAndTools>
  </div>
</template>

<script>
import Mapbox from '@/mixins/map/mapbox'
import LayoutMapAndTools from '../LayoutMapAndTools.vue'
import Map from './map.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    LayoutMapAndTools,
    Map,
  },
  data() {
    return {
      data_google_map: null,
    }
  },
  mixins: [Mapbox],
  computed: {
    ...mapGetters({
      address: 'address/address',
    }),
  },
}
</script>
